import React, { Component } from "react";
import styled, { css } from "styled-components";
import { getTween } from "../../../util";

// const backgroundColourChannel = getTween(33, 255, 0.05);

const FloatingDiv = styled.div(props => {
	let backgroundColourChannel = getTween(33, 255, props.elevation * 0.05);
	return css`
		display: ${props.visible ? "flex" : "none"};
		margin: auto;
		padding: 1rem;
		border-radius: 2px;
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
		justify-content: center;
		background-color: rgb(
			${backgroundColourChannel},
			${backgroundColourChannel},
			${backgroundColourChannel}
		);
		height: auto;
		width: auto;
		max-width: 95%;
		z-index: ${props.elevation};
	`;
});

const AbsoluteDiv = styled.div(
	props =>
		css`
			display: ${props.visible ? "flex" : "none"};
			width: 100%;
			height: 100%;
			align-items: center;
			position: absolute;
		`
);

class MaterialDiv extends Component {
	static defaultProps = { visible: true, elevation: 1 };

	render() {
		const childrenWithProps = React.Children.map(this.props.children, child =>
			React.cloneElement(child, {
				...child.props,
				elevation: child.props.elevation + this.props.elevation,
			})
		);

		return (
			<AbsoluteDiv visible={this.props.visible}>
				<FloatingDiv
					visible={this.props.visible}
					elevation={this.props.elevation}
				>
					<>{childrenWithProps}</>
				</FloatingDiv>
			</AbsoluteDiv>
		);
	}
}
export default MaterialDiv;
