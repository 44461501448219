import React, { Component } from "react";
import { Renderer, Welcome, VisTypeSelector } from "./components";
import styled, { css } from "styled-components";
import "./App.css";
import ChartEditor, { updateData } from "./components/ChartEditor";

const StyledDiv = styled.div(
	props =>
		css`
			background-color: #212121;
		`
);

let defaultPpv = {
	type: "flat",
	charts: [
		// {
		// 	data: [
		// 		{ date: "2020-01-01", location: "London", temperature: 10.0 },
		// 		{ date: "2020-01-01", location: "Paris", temperature: 15.0 },
		// 		{ date: "2020-01-01", location: "Berlin", temperature: 5 },
		// 	],
		// 	type: "line",
		// 	mapping: { x: "date", y: "temperature", filter: "location" },
		// },
	],
};

export default class extends Component {
	constructor(props) {
		super(props);
		this.handleClickNew = this.handleClickNew.bind(this);
		this.handleClickUpload = this.handleClickUpload.bind(this);
		this.visTypeSelectionHandler = this.visTypeSelectionHandler.bind(this);
		this.showChartEditor = this.showChartEditor.bind(this);
		this.updateData = updateData.bind(this);

		this.initialState = {
			visibility: {
				welcome: true,
				renderer: false,
				visTypeSelector: false,
				chartEditor: false,
			},
			ppv: { ...defaultPpv },
		};

		this.state = { ...this.initialState };

		this.fileSelector = this.buildFileSelector();
		console.log("initial state: ", this.state);
	}

	buildFileSelector() {
		const fileSelector = document.createElement("input");
		fileSelector.setAttribute("type", "file");
		fileSelector.setAttribute("multiple", "multiple");
		return fileSelector;
	}

	handleClickNew() {
		this.setState(prevState => ({
			visibility: {
				...prevState.visibility,
				welcome: false,
				visTypeSelector: true,
			},
		}));
	}

	handleFileSelect = e => {
		e.preventDefault();
		this.fileSelector.click();
	};

	handleClickUpload(e) {
		this.handleFileSelect(e);
	}

	visTypeSelectionHandler(selection) {
		this.setState(prevState => ({
			visibility: {
				...prevState.visibility,
				visTypeSelector: false,
				renderer: true,
			},
			ppv: { ...prevState.ppv, type: selection },
		}));
	}

	showChartEditor() {
		this.setState(prevState => ({
			visibility: {
				...prevState.visibility,
				chartEditor: true,
				renderer: false, //TODO remove this by getting stuff to centre
			},
		}));
	}

	render() {
		return (
			<StyledDiv className="PaperVis_Root">
				<Renderer
					className="PaperVis_Renderer"
					visible={this.state.visibility.renderer}
					ppv={this.state.ppv}
					showChartEditor={this.showChartEditor}
				/>
				<Welcome
					className="PaperVis_Welcome"
					visible={this.state.visibility.welcome}
					handleClickNew={this.handleClickNew}
					handleClickUpload={this.handleClickUpload}
				/>
				<VisTypeSelector
					className="PaperVis_VisTypeSelector"
					visible={this.state.visibility.visTypeSelector}
					handler={this.visTypeSelectionHandler}
				/>
				<ChartEditor
					className="PaperVis_ChartEditor"
					visible={this.state.visibility.chartEditor}
					updateFunction={this.updateData}
				/>
			</StyledDiv>
		);
	}
}
