import React, { Component } from "react";
import { MaterialDiv, MaterialButton } from "../Material";
import NamedInput from "../NamedInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faFileAlt } from "@fortawesome/free-solid-svg-icons";

const placeholders = {
	type: "flat",
	charts: [
		{
			data: [
				{ date: "1", location: "London", temperature: 10.0 },
				{ date: "2", location: "London", temperature: 9.0 },
				{ date: "3", location: "London", temperature: 10.0 },
				{ date: "4", location: "London", temperature: 7.0 },
				{ date: "5", location: "London", temperature: 11.0 },
				{ date: "1", location: "Paris", temperature: 15.0 },
				{ date: "2", location: "Paris", temperature: 12.0 },
				{ date: "3", location: "Paris", temperature: 11.0 },
				{ date: "4", location: "Paris", temperature: 8.0 },
				{ date: "5", location: "Paris", temperature: 4.0 },
				{ date: "1", location: "Berlin", temperature: 5 },
				{ date: "2", location: "Berlin", temperature: 1 },
				{ date: "3", location: "Berlin", temperature: 8 },
				{ date: "4", location: "Berlin", temperature: 7 },
				{ date: "5", location: "Berlin", temperature: 5 },
			],
			mapping: {
				x: "date",
				y: "temperature",
				filter: { property: "location", value: "London" },
			},
			config: {
				type: "line",
				domain: {
					x: { min: 1, max: 5 },
					y: { min: -10, max: 20 },
				}, //TODO use these in renderer
				padding: {
					top: 50,
					right: 50,
					bottom: 50,
					left: 50,
				},
			},
		},
	],
};

export function updateData(d, i) {
	let newCharts = this.state.ppv.charts || [];
	if (newCharts.length <= i) {
		newCharts.push(d);
	} else {
		newCharts[i] = d;
	}

	this.setState(prevState => ({
		visibility: {
			...prevState.visibility,
			chartEditor: false,
			renderer: true,
		},
		ppv: {
			...prevState.ppv,
			charts: newCharts,
		},
	}));

	console.log("newPpv: ", this.state.ppv);
}

class ChartEditor extends Component {
	static defaultProps = { visible: true };
	constructor(props) {
		super(props);
		this.state = {
			data: JSON.stringify(placeholders.charts[0].data, null, 2),
			mapping: JSON.stringify(placeholders.charts[0].mapping, null, 2),
			config: JSON.stringify(placeholders.charts[0].config, null, 2),
		};
		this.handleUpdate = this.handleUpdate.bind(this);
		this.restringifyDataAndMapping = this.restringifyDataAndMapping.bind(this);
	}

	restringifyDataAndMapping() {
		try {
			let newDataString = this.restringify(this.state.data);
			try {
				let newMappingString = this.restringify(this.state.mapping);
				try {
					let newConfig = this.restringify(this.state.config);
					this.setState({
						data: newDataString,
						mapping: newMappingString,
						config: newConfig,
					});
				} catch {
					alert("invalid chart config");
				}
			} catch {
				alert("invalid mapping");
			}
		} catch {
			alert("invalid data");
		}
	}

	restringify(jsonString) {
		return JSON.stringify(JSON.parse(jsonString), null, 2);
	}

	handleUpdate(data, key) {
		this.setState({ [key]: data });
	}

	rebuildJSON(dataString, mappingString, configString) {
		return {
			data: JSON.parse(dataString),
			mapping: JSON.parse(mappingString),
			config: JSON.parse(configString),
		};
	}

	parseThenUpdate() {
		try {
			this.props.updateFunction(
				this.rebuildJSON(
					this.state.data,
					this.state.mapping,
					this.state.config
				),
				0
			);
		} catch {
			alert("could not save, error in code");
		}
	}

	render() {
		return (
			<MaterialDiv visible={this.props.visible}>
				<NamedInput
					title="data"
					code={this.state.data}
					changeAction={this.handleUpdate}
					placeholder={placeholders.charts[0].data}
				/>
				<NamedInput
					title="mapping"
					code={this.state.mapping}
					changeAction={this.handleUpdate}
					placeholder={placeholders.charts[0].mapping}
				/>
				<NamedInput
					title="config"
					code={this.state.config}
					changeAction={this.handleUpdate}
					placeholder={placeholders.charts[0].config}
				/>
				<MaterialButton
					text="Format"
					clickHandler={this.restringifyDataAndMapping}
					// icon={<i className="fas fa-sparkles"></i>}
					icon={<FontAwesomeIcon icon={faFileAlt} />}
				/>
				<MaterialButton
					text="Save"
					clickHandler={() => {
						this.parseThenUpdate();
					}}
					icon={<FontAwesomeIcon icon={faSave} />}
				/>
			</MaterialDiv>
		);
	}
}

export default ChartEditor;
