import React, { Component } from "react";
import styled, { css } from "styled-components";

import * as d3 from "d3";

import {
	linearScale,
	linearScaleMaximised,
	chartLineGenerator,
} from "../../util";

let { line, scaleLinear, scaleBand, extent, curveMonotoneX } = d3;

const StyledDiv = styled.div(
	props => css`
    // visibility: ${props.visible ? "visible" : "collapse"};
    display: ${props.visible ? "block" : "none"};
  `
);

export function showNewChartModal() {
	alert("newchart");
}

function renderPaperVisType(props) {
	if (props.ppv.type === "flat") {
		return (
			<>
				<rect
					x="-490"
					y="-490"
					width="980"
					height="980"
					fill="none"
					strokeWidth="1"
					stroke="rgb(0,0,0)"
				/>
				{(() => {
					if (
						props.ppv.charts != null &&
						Array.isArray(props.ppv.charts) &&
						props.ppv.charts.length > 0
					) {
						//TODO add for case where papervis content

						//https://www.dashingd3js.com/svg-paths-and-d3js
						//https://stackblitz.com/edit/d3-react-line-chart?file=index.js

						//TODO remove chart array index hardcoding

						let filteredData = props.ppv.charts[0].data.filter(
							d =>
								d[props.ppv.charts[0].mapping.filter.property] ==
								[props.ppv.charts[0].mapping.filter.value]
						);

						let filteredChart = {
							...props.ppv.charts[0],
							data: props.ppv.charts[0].data.filter(
								d =>
									d[props.ppv.charts[0].mapping.filter.property] ==
									[props.ppv.charts[0].mapping.filter.value]
							),
						};

						//TODO find way to pass array to this function as params

						const yScale = scaleLinear()
							.domain(
								extent(
									filteredChart.data,
									d => d[props.ppv.charts[0].mapping.y]
								)
							)
							.range([480, -480])
							.nice();

						const xScale = scaleLinear()
							.domain(
								extent(
									filteredChart.data,
									d => d[props.ppv.charts[0].mapping.x]
								)
							)
							.range([-480, 480])
							.nice();

						// function manualScale(domain, range) {
						// 	return scaleLinear()
						// 		.domain([domain.min, domain.max])
						// 		.range([range.min, range.max])
						// 		.nice();
						// }

						// const yManScale = manualScale(
						// 	{ min: 25, max: -25 },
						// 	{ min: -480, max: 480 }
						// );

						const lineGenerator = line()
							// .x(d => xScale(d[props.ppv.charts[0].mapping.x]))
							.x(d =>
								linearScale(
									d[props.ppv.charts[0].mapping.x],
									{ min: 1, max: 5 },
									{ min: -480, max: 480 }
								)
							)
							// .y(d => yScale(d[props.ppv.charts[0].mapping.y]))
							// .y(d => thisManScale(d[props.ppv.charts[0].mapping.y]))
							.y(d =>
								linearScale(
									d[props.ppv.charts[0].mapping.y],
									{ min: 25, max: -25 },
									{ min: -480, max: 480 }
								)
							)
							.curve(curveMonotoneX);

						return (
							<>
								<path
									stroke="black"
									strokeWidth="1"
									fill="none"
									d={"M-480,480 " + "l 0,-960 " + "l 960,0 " + "l 0,960 "}
								/>
								<path
									stroke="black"
									strokeWidth="1"
									fill="none"
									strokeDasharray="10 10"
									d={"M-480,480 " + "l 950,0"}
								/>
								<g transform={"scale(" + 960 / 1000 + ")"}>
									{/* //TODO add non-hardcoded values */}
									<path
										stroke="black"
										strokeWidth="1"
										fill="none"
										// d={lineGenerator(filteredChart.data)}
										d={chartLineGenerator(filteredChart)}
									/>
								</g>
							</>
						);
					} else {
						return (
							<>
								<rect
									x="-475"
									y="-475"
									width="950"
									height="950"
									fill="white"
									strokeWidth="1"
									strokeDasharray="10 5"
									stroke="rgb(0,0,0)"
									cursor="pointer"
									onClick={props.showChartEditor}
								/>
								<path
									stroke="black"
									strokeWidth="5"
									d={
										"M0,0 " +
										"l 0,100 " +
										"l 0,-200 " +
										"l 0,100 " +
										"l -100,0 " +
										"l 200,0 "
									}
								/>
							</>
						);
					}
				})()}
			</>
		);
	}
}

// const scale() {
// 	// https://www.d3indepth.com/scales/

// 	// if (!isNaN(data)) {
// 	// 	return scaleLinear()
// 	// 		.domain(extent(data, point))
// 	// 		.range([max, 0])
// 	// 		.nice();
// 	// }
// 	// return data;
// }

export default class extends Component {
	static defaultProps = { visible: true };

	render() {
		return (
			<StyledDiv
				style={{
					display: this.props.visible ? "block" : "none",
					width: "90%",
					height: "90%",
					margin: "auto",
				}}
			>
				<svg
					viewBox="-500 -500 1000 1000"
					style={{
						width: "100%",
						height: "100%",
						backgroundColor: "white",
					}}
				>
					<g
					// transform={"scale(" + 500 / 100 + ")"}
					// transform={(() => {
					// 	console.log("scale(" + 500 / 100 + ")");

					// 	return "scale(" + 500 / 100 + ")";
					// })()}

					// transform={(function() {
					// 	console.log("scale(" + 500 / 100 + ")");
					// 	return "scale(" + 500 / 100 + ")";
					// })()}
					>
						{renderPaperVisType(this.props)}
					</g>
					{/* <text x="500" y="50">
						{this.props.ppv.type}
					</text> */}
				</svg>
			</StyledDiv>
		);
	}
}
