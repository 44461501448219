import * as d3 from "d3";

let { line, scaleLinear, scaleBand, extent, curveMonotoneX } = d3;

export function getTween(from, to, percentage) {
	return from + percentage * (to - from);
}

export function linearScale(value, domain, range) {
	if (Array.isArray(domain) && domain.length !== 2) {
		console.warn("bad scale domain array: ", domain);
		console.warn("truncating domain array");
		domain = [domain[0], domain[1]];
		console.warn("new domain array: ", domain);
	}

	if (Array.isArray(range) && range.length !== 2) {
		console.warn("bad scale range array: ", range);
		console.warn("truncating range array");
		range = [range[0], range[1]];
		console.warn("new range array: ", range);
	}

	return scaleLinear()
		.domain(Array.isArray(domain) ? domain : [domain.min, domain.max])
		.range(Array.isArray(range) ? range : [range.min, range.max])
		.nice()(value);
}

export function linearScaleMaximised(value, data, range, accessor) {
	if (accessor != null) {
		return linearScale(value, extent(data, accessor), range);
	} else {
		return linearScale(value, extent(data), range);
	}
}

export function chartLineGenerator(chart, curveType) {
	console.log("chartLineGenerator: ", chart);
	return line()
		.x(d =>
			linearScale(
				d[chart.mapping.x],
				chart.config.hasOwnProperty("domain") &&
					chart.config.domain.hasOwnProperty("x")
					? chart.config.domain.x
					: extent(chart.data, d2 => d2[chart.mapping.x]),
				{
					min: -500,
					max: 500,
				}
			)
		)
		.y(d =>
			linearScale(
				d[chart.mapping.y],
				chart.config.hasOwnProperty("domain") &&
					chart.config.domain.hasOwnProperty("y")
					? chart.config.domain.y
					: extent(chart.data, d2 => d2[chart.mapping.y]),
				{
					min: 500,
					max: -500,
				}
			)
		)
		.curve(curveType || curveMonotoneX)(chart.data);
}
