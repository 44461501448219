import React, { Component } from "react";
import styled, { css } from "styled-components";
import { getTween } from "../../../util";

const StyledButton = styled.button(props => {
	let backgroundColourChannel = getTween(33, 255, props.elevation * 0.05);
	return css`
		width: 100px;
		height: 100px;
		font-size: 1em;
		border: transparent;
		border-radius: 2px;
		background-color: rgb(
			${backgroundColourChannel},
			${backgroundColourChannel},
			${backgroundColourChannel}
		);
		color: white;
		margin: 1rem;
		z-index: ${props.elevation};

		outline: none;
		cursor: pointer;

		/* -webkit-user-drag: none;
			-khtml-user-drag: none;
			-moz-user-drag: none;
			-o-user-drag: none; */
		user-drag: none;

		-webkit-touch-callout: none;

		/* -webkit-user-select: none;
			-khtml-user-select: none;
			-moz-user-select: none;
			-o-user-select: none; */
		user-select: none;

		box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
		transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

		&:hover {
			box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
				0 10px 10px rgba(0, 0, 0, 0.22);
		}
	`;
});

class MaterialButton extends Component {
	static defaultProps = {
		visible: true,
		elevation: 1,
		text: "",
		icon: null,
		clickHandler: () => {
			console.log("no handler attached");
		},
	};

	render() {
		return (
			<StyledButton
				onClick={this.props.clickHandler}
				elevation={this.props.elevation}
			>
				<div>{this.props.icon}</div>
				<div>{this.props.text}</div>
				<div>{this.props.children}</div>
			</StyledButton>
		);
	}
}

export default MaterialButton;
