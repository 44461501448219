import React, { Component } from "react";
import styled, { css } from "styled-components";
import { MaterialDiv, MaterialButton } from "../Material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartArea, faCube } from "@fortawesome/free-solid-svg-icons";

class VisTypeSelector extends Component {
	static defaultProps = { visible: true };

	render() {
		return (
			<MaterialDiv visible={this.props.visible}>
				<MaterialButton
					text="Flat"
					clickHandler={() => this.props.handler("flat")}
					icon={<FontAwesomeIcon icon={faChartArea} />}
				/>
				<MaterialButton
					text="Cube"
					clickHandler={() => this.props.handler("cube")}
					icon={<FontAwesomeIcon icon={faCube} />}
				></MaterialButton>
			</MaterialDiv>
		);
	}
}

export default VisTypeSelector;
