import React, { Component } from "react";
import styled, { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faFileUpload } from "@fortawesome/free-solid-svg-icons";
import { MaterialDiv, MaterialButton } from "../Material";

class Welcome extends Component {
	static defaultProps = { visible: true };

	render() {
		return (
			<MaterialDiv visible={this.props.visible} elevation={1}>
				<MaterialButton
					text="New"
					icon={<FontAwesomeIcon icon={faFile} />}
					clickHandler={this.props.handleClickNew}
				/>
				<MaterialButton
					text="Upload"
					icon={<FontAwesomeIcon icon={faFileUpload} />}
					clickHandler={this.props.handleClickUpload}
				/>
			</MaterialDiv>
		);
	}
}

export default Welcome;
