import React, { Component } from "react";
import styled, { css } from "styled-components";

import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";

// const StyledEditor = styled(Editor)`
// 	height: 50%;
// `;

const StyledDiv = styled.div(
	props => css`
		padding: 1rem;

		label {
			color: white;
			text-align: left;
			display: block;
		}

		/* Syntax highlighting */
		.token.comment,
		.token.prolog,
		.token.doctype,
		.token.cdata {
			color: #90a4ae;
		}
		.token.punctuation {
			color: #9e9e9e;
		}
		.namespace {
			opacity: 0.7;
		}
		.token.property,
		.token.tag,
		.token.boolean,
		.token.number,
		.token.constant,
		.token.symbol,
		.token.deleted {
			color: #e91e63;
		}
		.token.selector,
		.token.attr-name,
		.token.string,
		.token.char,
		.token.builtin,
		.token.inserted {
			color: #4caf50;
		}
		.token.operator,
		.token.entity,
		.token.url,
		.language-css .token.string,
		.style .token.string {
			color: #795548;
		}
		.token.atrule,
		.token.attr-value,
		.token.keyword {
			color: #3f51b5;
		}
		.token.function {
			color: #f44336;
		}
		.token.regex,
		.token.important,
		.token.variable {
			color: #ff9800;
		}
		.token.important,
		.token.bold {
			font-weight: bold;
		}
		.token.italic {
			font-style: italic;
		}
		.token.entity {
			cursor: help;
		}
	`
);

class NamedInput extends Component {
	static defaultProps = { title: "" };

	render() {
		return (
			<StyledDiv>
				<label htmlFor={this.props.title}>{this.props.title}</label>

				<div
					style={{
						height: "300px",
						width: "250px",

						minWidth: "300px",
						minHeight: "250px",

						maxWidth: "750px",
						maxHeight: "750px",

						overflow: "auto",
						resize: "both",
					}}
				>
					<Editor
						textareaId={this.props.title}
						value={this.props.code}
						placeholder={this.props.placeholder}
						// onValueChange={code => this.setState({ code })}
						// onValueChange={e => this.props.changeAction(e, "data")}
						onValueChange={data =>
							this.props.changeAction(data, this.props.title)
						}
						highlight={code => highlight(code, languages.js)}
						padding={10}
						style={{
							fontFamily: '"Fira code", "Fira Mono", monospace',
							fontSize: 12,
							minHeight: "300px",
						}}
					/>
				</div>
			</StyledDiv>
		);
	}
}

export default NamedInput;
